.contact{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 4rem;
      /* scroll */
  padding-top: 5rem;
}

.right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.right .user ,.mail ,.message{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
}
.message{
    height: 6rem;
}

textarea{
    height: 4rem!important;
}

.texte span:nth-child(1){
    color: var(--black);
    font-size: 5rem;
    font-weight: bold;
}

.texte span:nth-child(3){
    color: var(--orange);
    font-size: 4rem;
    font-weight: bold;
}

form{
margin-top: 5rem;
}

.blur{
    top: 1rem;
    left: 8rem;
}

@media screen and (max-width: 480px) {
    .contact{
        padding: 0;
        flex-direction: column;
        gap: 5rem;
        height: 40rem;
    }
    .right .user{
        width: 16rem;
    }

}   

@media screen and (max-width: 480px) {
    .contact{
        margin-top: 18rem;
    }
    .contact-form{
        padding: 0;
        flex-direction: column;
        gap: 5rem;
        height: 40rem;
    }
    .right .user{
        width: 20rem;
    }

    form{
        margin-top: 1rem;
        }
}   