.intro{
    height: 100vh;
    display: flex;
    right: 77vh;
    margin-top: 6rem;
}

.i-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.i-name >:nth-child(1){
    color: var(--black);
    font-size: 5rem;
    font-weight: bold;
}

.i-name >:nth-child(3){
    color: var(--orange);
    font-size: 4rem;
    font-weight: bold;
}

.i-name >:nth-child(5){
    color: var(--gray);
    font-weight: 100;
    font-size: 20px;
    margin-top: 20px;

   
}

.i-button{
    height: 3rem;
    width: 10rem;
}

.i-icons{
    margin-top: 10rem;
    display: flex;
    gap: 10rem;
}

.i-icons >*{
    transform: scale(2);
}

.i-icons>*:hover{
    cursor: pointer;
}

.i-right{
    background-image: url("../../img/MonAvatar.png");
    flex: 1;
    position: relative;  
    background-repeat: no-repeat; 
}

i-right>*{
    position: absolute;
    z-index: 1;
}

.i-right>:nth-child(1){
    transform: scale(0.8);
}

.blur{
    position: absolute;
    width: 25rem;
    height: 14rem;
    border-radius: 50%;
    z-index: -9;
    top: 2%;
    left: 56%;
    filter: blur(72px);
}
.flot1 {
    margin-top: 2%;
    margin-left: 58%;

}
.flot2 {
    margin-top: -5px;

}

@media screen and (max-width: 480px) {
    .intro {
      flex-direction: column;
      gap: 7rem;
      height: 64rem;
    }
  
    .i-right {
      transform: scale(0.8);
      left: -3rem;
      background-image: none;
    }
    .i-right .blur {
      display: none;
    }
    .flot1 {
        margin-top: -5rem;

    }
    .floating-div:nth-of-type(1){
      /* display: none; */
      top: -7rem !important;
    }
    .i-icons{
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        justify-content: center;
    }
    .i-icons >*{
        transform: scale(0.5);
    }
  }