.floatingdiv{
    justify-content: space-around;
    display: flex;
    width: 240px;
    height: 4.5rem;
    align-items: center;
    /* From https://css.glass */
background: rgba(255, 170, 62, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 170, 62, 0.3);
}

.floatingdiv>img{
    transform: scale(0.4);
}

.floatingdiv>span{
    font-family: sans-serif;
    font-size: 16px;
}