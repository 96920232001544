.footer {
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -20rem;
  }
  .footer-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    gap: 4rem;
    color: white;
  }
  .footer-icon {
    display: flex;
    gap: 3rem;
  }
  span{ 
    color: gray;
  }
  @media screen and (max-width: 480px){
    .footer{
      margin-top: 5rem;
    }
    .f-content{
      transform: scale(0.5);
    }
    .footer-icon {
      display: flex;
      top: 2rem;

    }
  }