.portfolio{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 70vh;
}

.portfolio span:nth-child(1){
    color: var(--black);
    font-size: 5rem;
    font-weight: bold;
}

.portfolio span:nth-child(2){
    color: var(--orange);
    font-size: 4rem;
    font-weight: bold;
}

.portfolio {
    overflow: visible!important;
}


.swiper-slide {
    display: block;
    object-fit: cover;
}
.img-carousel {
    max-height : 500px;
    max-width : 500px;
    object-fit: contain;
    }

.carousel{
    position: relative;
}
.carousel .thumbs{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 10px;
}

.carousel .overlay{
    width: 80%;
    height: 400px;
    position: absolute;
    transform: translate(-50%);
    left: 10%;
    padding: 0.4rem 1.5rem;
    text-align: left;
    background: rgba(0, 0, 0, 0.5);
    color: white;
}