.services{
    padding: 0 3rem 0 3rem ;
    display: flex;
    height: 90vh;
}

.s-left{
    display: flex;
    flex-direction: column;
    position: relative;
}

.s-left > :nth-child(1){
    color: var(--orange);
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.s-button{
    width: 15rem;
    height: 3rem;
    margin-top: 1rem;
}

.s-right{
    position: relative;
}

.s-right >*{
    position: absolute;
}

.Card1{
  top: 1rem;
  left: 20rem;
}

.Card2{
  top: 15rem;
  left: 1rem;
}

.Card3{
  top: 25rem;
  left: 34rem;
}

@media screen and (max-width: 480px) {
    .services {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      gap: 5rem;
      height: 66rem;
      padding: 0;
      margin-top: -4rem;
    }
    .s-right{
      display: flex;
      flex-direction: column;
    }
    .cards {
      display: flex;
      flex-direction: column;
      gap: 17rem;
    }
    .s-right >*{
      display: flex;
      flex-direction: column;
      position: relative;
  }
  .Card1{
    top: auto;
    left: auto;
    margin-bottom: 10px;
  }
  .Card2{
    top: auto;
    left: auto;
    margin-bottom: 10px;
  }
  .Card3{
    top: auto;
    left: auto;
    margin-bottom: 10px;
  }
  } 